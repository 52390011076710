import React from 'react';
import {
  PapiDeviation,
  PapiUser,
  PapiGallection,
  PapiGroup,
} from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { BrowseItem } from '../../../types/api';
import { ItemTypes } from '../../../constants';
import { showLessLikeThis } from '../../actions/recommendation';
import CollectionCard from './CollectionCard';
import MiniGroupCard from './MiniGroupCard';
import MiniArtistCard from './MiniArtistCard';
import DeviationCard from './DeviationCard';

import s from './ItemCard.scss';

export interface Props {
  type: ItemTypes;
  item: BrowseItem;
  itemIndex: number;
  width: number;
  height: number;
  style?: React.CSSProperties;
  canShowLess?: boolean;
  disableShowLess?: boolean;
  inclusiveFooter?: boolean;
  showTierPromotionTooltip?: boolean;
  responsive?: boolean;
  allowMobileCard?: boolean;
  dataHook?: string;
  onShowLess: typeof showLessLikeThis;
  onRemove?: (deviation: PapiDeviation, offset: number) => void;
}

const ItemCard: React.FC<Props> = ({
  type,
  item,
  itemIndex,
  style = {},
  inclusiveFooter,
  showTierPromotionTooltip,
  responsive,
  width,
  height,
  dataHook,
  canShowLess,
  allowMobileCard,
  onRemove,
  onShowLess,
}) => {
  let result: React.JSX.Element;
  switch (type) {
    case ItemTypes.Deviation:
      const deviation = item as PapiDeviation;
      result = (
        <DeviationCard
          deviation={deviation}
          offset={itemIndex}
          width={width}
          height={height}
          style={style}
          canShowLess={canShowLess}
          responsive={responsive}
          allowMobileCard={allowMobileCard}
          onRemove={onRemove}
          onShowLess={onShowLess}
          showTierPromotionTooltip={showTierPromotionTooltip}
        />
      );
      break;
    case ItemTypes.User:
      result = (
        <MiniArtistCard
          user={item as PapiUser}
          style={style}
          dataHook={dataHook}
        />
      );
      break;
    case ItemTypes.Gallection:
      result = (
        <CollectionCard
          gallection={item as PapiGallection}
          width={width}
          height={height}
          style={style}
          inclusiveFooter={inclusiveFooter}
        />
      );
      break;
    case ItemTypes.Group:
      result = (
        <MiniGroupCard
          className={s['group-card']}
          style={style}
          group={item as PapiGroup}
          dataHook={dataHook}
        />
      );
      break;
    default:
      return null;
  }

  return (
    <BiLoggerContextProvider value={{ _rankIndex: itemIndex }}>
      {result}
    </BiLoggerContextProvider>
  );
};
ItemCard.displayName = 'ItemCard';

export default ItemCard;
